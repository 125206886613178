import { useNavigate } from "react-router-dom";
import './IllustrationPhotos.css';

function IllustrationPhotos({ slike }: any) {

    const navigator = useNavigate();

    var edited = ["photo_1", "photo_2", "photo_3", "photo_10", "photo_17", "photo_18"];

    const slikeView: any = [];
    slike.forEach((slika: any) => {
        if (edited.includes(slika.imeSlike)) {
            slikeView.push(
                <div className="col-12 Slike">
                    <img src={require(`./images/slike/${slika.imeSlike}.jpg`)} alt='' className="img-fluid"></img>
                </div>
            )
        } else {
            slikeView.push(
                <div className="col-6 Slike">
                    <img src={require(`./images/slike/${slika.imeSlike}.jpg`)} alt='' className="img-fluid"></img>
                </div>
            )
        }
    });

    function handleChange(e: any) {
        var url = e.target.name;
        if (url === 'home')
            navigator('/');
        else
            navigator(url);
    }

    return (
        <div>
            <div className='row align-items-center App-header fixed-top'>
                <div className='col-2'>
                    <button className="btn btn-link  App-link" name="home" onClick={(e) => handleChange(e)}>
                        LOLICA
                    </button>
                </div>
                <div className='col-8'>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/about_me" onClick={(e) => handleChange(e)}>ABOUT ME</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/animation" onClick={(e) => handleChange(e)}>ANIMATION</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/illustration_photos" onClick={(e) => handleChange(e)} disabled>ILLUSTRATION & PHOTOS</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/personal_projects" onClick={(e) => handleChange(e)}>PERSONAL PROJECTS</button>
                </div>
                <div className='col-2'>
                    <a href="https://www.instagram.com/lazovic_oljica" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/instagram.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                    <a href="https://www.behance.net/oljicalazovic" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/behance.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                    <a href="https://vimeo.com/user124165686" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/vimeo.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                </div>
            </div>
            <div className="container">
                <div className="row My-row">
                    {slikeView}
                </div>
            </div>
        </div>
    );
}

export default IllustrationPhotos;