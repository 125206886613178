import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import './Main.css'

function Main() {

    const navigator = useNavigate();

    function handleChange(e: any) {
        var url = e.target.name;
        if (url === 'home')
            navigator('/');
        else
            navigator(url);
    }

    function changeGif(e: any) {
        if (document.getElementById('gif_1')?.style.display === 'initial') {
            if (e.target.id === 'left') {
                document.getElementById('gif_1')!.style.display = 'none'
                document.getElementById('gif_2')!.style.display = 'none'
                document.getElementById('gif_3')!.style.display = 'initial'
            } else if (e.target.id === 'right') {
                document.getElementById('gif_1')!.style.display = 'none'
                document.getElementById('gif_2')!.style.display = 'initial'
                document.getElementById('gif_3')!.style.display = 'none'
            }
        } else if (document.getElementById('gif_2')?.style.display === 'initial') {
            if (e.target.id === 'left') {
                document.getElementById('gif_1')!.style.display = 'initial'
                document.getElementById('gif_2')!.style.display = 'none'
                document.getElementById('gif_3')!.style.display = 'none'
            } else if (e.target.id === 'right') {
                document.getElementById('gif_1')!.style.display = 'none'
                document.getElementById('gif_2')!.style.display = 'none'
                document.getElementById('gif_3')!.style.display = 'initial'
            }
        } else if (document.getElementById('gif_3')?.style.display === 'initial') {
            if (e.target.id === 'left') {
                document.getElementById('gif_1')!.style.display = 'none'
                document.getElementById('gif_2')!.style.display = 'initial'
                document.getElementById('gif_3')!.style.display = 'none'
            } else if (e.target.id === 'right') {
                document.getElementById('gif_1')!.style.display = 'initial'
                document.getElementById('gif_2')!.style.display = 'none'
                document.getElementById('gif_3')!.style.display = 'none'
            }
        }
    }

    return (
        <div>
            <div className='row align-items-center App-header fixed-top'>
                <div className='col-2'>
                    <button className="btn btn-link  App-link" name="home" onClick={(e) => handleChange(e)}>
                        LOLICA
                    </button>
                </div>
                <div className='col-8'>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/about_me" onClick={(e) => handleChange(e)}>ABOUT ME</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/animation" onClick={(e) => handleChange(e)}>ANIMATION</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/illustration_photos" onClick={(e) => handleChange(e)}>ILLUSTRATION & PHOTOS</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/personal_projects" onClick={(e) => handleChange(e)}>PERSONAL PROJECTS</button>
                </div>
                <div className='col-2'>
                    <a href="https://www.instagram.com/lazovic_oljica" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/instagram.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                    <a href="https://www.behance.net/oljicalazovic" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/behance.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                    <a href="https://vimeo.com/user124165686" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/vimeo.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                </div>
            </div>
            <div className="container-fluid Title-margin">
                <div className="row text-start">
                    <div className="col-1">
                    </div>
                    <div className="col-10">
                        <div className="Main-overlay-text">
                            <img src={require(`./images/Main_logo.png`)} alt='' width={1000} className="img-fluid"></img>
                        </div>
                    </div>
                    <div className="col-1">
                    </div>
                    <div className="col-1">
                    </div>
                    <div className="col-10 Main-title-desc">
                        <p className="Main-desc">Multimedia artist <br /> based in Belgrade</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1 Main-arrows text-right">
                        <img src={require(`./images/util/arrow_left.png`)} alt='' onClick={(e) => changeGif(e)} id="left" className="img-fluid Arrows"></img>
                    </div>
                    <div className="col-10">
                        <img src={require(`./images/gifs/gif_1.gif`)} alt='' className="img-fluid" style={{ display: "none" }} id="gif_1"></img>
                        <img src={require(`./images/gifs/gif_2.gif`)} alt='' className="img-fluid" style={{ display: "none" }} id="gif_2"></img>
                        <img src={require(`./images/gifs/gif_3.gif`)} alt='' className="img-fluid" style={{ display: "initial" }} id="gif_3"></img>
                    </div>
                    <div className="col-1 Main-arrows">
                        <img src={require(`./images/util/arrow_right.png`)} alt='' onClick={(e) => changeGif(e)} id="right" className="img-fluid Arrows"></img>
                    </div>
                </div>
            </div>
            <div className="row Main-bottom-info">
                <div className="col-2 Main-info">
                </div>
                <div className="col-8 Main-info">
                    <p className="Main-text-margins">
                        I have finished book design and illustration on the University of Arts in Belgrade, along with finalizing my masters in creative photography. My visual work is meant to be somewhere amongst the surreal and raw. By combining all the skills I have gained over the years of experience at the university and professional career a get a chance to give motion to the digital.
                        Always striving to develop new striking ideas that are unique with expressing the main core.
                    </p>
                </div>
                <div className="col-2 Main-info">
                </div>
                <div className="col-2 Main-email">
                </div>
                <div className="col-8 Main-email">
                    <h5 className="Main-text-margins">
                        Email me at
                        <img src={require(`./images/email-arrow.png`)} alt='' width={500} className="img-fluid"></img>
                        oljalazovic4@gmail.com
                    </h5>
                </div>
                <div className="col-2 Main-email">
                </div>
            </div>
        </div>
    );
}

export default Main;