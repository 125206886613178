import { useNavigate } from "react-router-dom";
import './PersonalProjects.css'

function PersonalProjects() {

    const navigator = useNavigate();

    function handleChange(e: any) {
        var url = e.target.name;
        if (url === 'home')
            navigator('/');
        else
            navigator(url);
    }

    return (
        <div>
            <div className='row align-items-center App-header fixed-top'>
                <div className='col-2'>
                    <button className="btn btn-link  App-link" name="home" onClick={(e) => handleChange(e)}>
                        LOLICA
                    </button>
                </div>
                <div className='col-8'>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/about_me" onClick={(e) => handleChange(e)}>ABOUT ME</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/animation" onClick={(e) => handleChange(e)}>ANIMATION</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/illustration_photos" onClick={(e) => handleChange(e)}>ILLUSTRATION & PHOTOS</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/personal_projects" onClick={(e) => handleChange(e)} disabled>PERSONAL PROJECTS</button>
                </div>
                <div className='col-2'>
                    <a href="https://www.instagram.com/lazovic_oljica" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/instagram.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                    <a href="https://www.behance.net/oljicalazovic" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/behance.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                    <a href="https://vimeo.com/user124165686" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/vimeo.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                </div>
            </div>
            <div className="container">
                <div className="row Single-card text-start">
                    <div className="col-12">
                        <p className="Title">“And the fear is gone”</p>
                        <p className="Product-type">( instalation and video )</p>
                        <a href="https://vimeo.com/895818678" target="_blank" rel="noopener noreferrer">
                            <img src={require(`./images/projekti/photo_1.jpg`)} alt='' className="img-fluid Projekti-slike"></img>
                        </a>
                        <p>
                            Installation within the video has been left on the gate of the “Pimnica”  and was made in 2023
                            “And the fear is gone”. Video has been exhibited on 25. colony of Mokranjcevi dani
                        </p>
                    </div>
                </div>
                <div className="row Single-card text-start">
                    <div className="col-12">
                        <p className="Title">“Branch lady”</p>
                        <p className="Product-type">( instalation )</p>
                    </div>
                    <div className="col-6">
                        <img src={require(`./images/projekti/photo_2.jpg`)} alt='' className="img-fluid Projekti-slike"></img>
                    </div>
                    <div className="col-6">
                        <img src={require(`./images/projekti/photo_3.jpg`)} alt='' className="img-fluid Projekti-slike"></img>
                    </div>
                    <div className="col-12">
                        <p>
                            This instalation was made at colony in Tršić in 2023.
                            The “Branch lady” as the name says is made entirely of sticks of different kinds of wood and is approximatly 2m tall. She also has mirrors for eyes so that the viewer could see themselves in her eyes.
                        </p>
                    </div>
                </div>
                <div className="row Single-card text-start">
                    <div className="col-12">
                        <p className="Title">“Cloud in custody”</p>
                        <p className="Product-type">( copper sculpture )</p>
                        <img src={require(`./images/projekti/photo_4.jpg`)} alt='' className="img-fluid Projekti-slike"></img>
                        <p>
                            This sculpture was made at the end of 2022 at the workshop of the profesor Marko Ladjušić. It is made of copper wires and was soldered with tin.
                        </p>
                    </div>
                </div>
                <div className="row Single-card text-start">
                    <div className="col-12">
                        <p className="Title">“I love it, now put it on fire”</p>
                        <p className="Product-type">( concept/ limited series )</p>
                        <img src={require(`./images/projekti/photo_7.jpg`)} alt='' className="img-fluid Projekti-slike"></img>
                    </div>
                </div>
                <div className="row Single-card text-start">
                    <div className="col-12">
                        <p className="Title">“What if”</p>
                        <p className="Product-type">( video )</p>
                        <img src={require(`./images/projekti/photo_8.jpg`)} alt='' className="img-fluid Projekti-slike"></img>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersonalProjects;