import { useNavigate } from "react-router-dom";
import './AboutMe.css'

function AboutMe() {

    const navigator = useNavigate();

    function handleChange(e: any) {
        var url = e.target.name;
        if (url === 'home')
            navigator('/');
        else
            navigator(url);
    }

    return (
        <div>
            <div className='row align-items-center App-header fixed-top'>
                <div className='col-2'>
                    <button className="btn btn-link  App-link" name="home" onClick={(e) => handleChange(e)}>
                        LOLICA
                    </button>
                </div>
                <div className='col-8'>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/about_me" onClick={(e) => handleChange(e)} disabled>ABOUT ME</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/animation" onClick={(e) => handleChange(e)}>ANIMATION</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/illustration_photos" onClick={(e) => handleChange(e)}>ILLUSTRATION & PHOTOS</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/personal_projects" onClick={(e) => handleChange(e)}>PERSONAL PROJECTS</button>
                </div>
                <div className='col-2'>
                    <a href="https://www.instagram.com/lazovic_oljica" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/instagram.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                    <a href="https://www.behance.net/oljicalazovic" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/behance.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                    <a href="https://vimeo.com/user124165686" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/vimeo.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                </div>
            </div>
            <div className="container">
                <div className="row Single-card text-start">
                    <div className="col-2">
                        <img src={require(`./images/avatar.jpg`)} alt='' className="img-fluid Projekti-slike"></img>
                    </div>
                    <div className="col-10">
                        <p className="Aboutme-start">My name is Olivera Lazović, I'm from Serbia. My work has a wide spectrum of visualization, from illustration to motion. From animation and other media to personal projects or grupe ones that are a wonderful experience in my carrier. I have a bachelor degree in applied graphics and books and also got my masters degree, both, at the Faculty of Applied Arts, in Creative photography.</p>
                    </div>
                    <div className="col-12 Aboutme">
                        <h3>Exhibitions & experience</h3>
                        <p>
                            2018
                            <br />
                            Exhibited in numerous shows, including Lino Art 2018, Up stream down 2019, Exhibition of Graphics Students with Professors 2020, and others,
                            <br />
                            <br />
                            2021
                            <br />
                            First prize at the “Europe is Here” competition for the installation titled "Growth",
                            Participant in the Multimedia Colony in Tršić 2021 and 2022,
                            <br />
                            <br />
                            2022
                            <br />
                            Guest lecturer at the University of Banja Luka, Faculty of Architecture, Civil Engineering, and Geodesy, March.
                            <br />
                            <br />
                            2022
                            <br />
                            Solo exhibition "Body-Antibody" at the Cultural Center Belgrade, September.
                            <br />
                            <br />
                            2023
                            <br />
                            Participation in the Youth Biennial ULUS "My name is quite ordinary"
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutMe;