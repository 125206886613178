import { useNavigate } from "react-router-dom";
import './Animation.css'

function Animation() {

    const navigator = useNavigate();

    function handleChange(e: any) {
        var url = e.target.name;
        if (url === 'home')
            navigator('/');
        else
            navigator(url);
    }

    return (
        <div>
            <div className='row align-items-center App-header fixed-top'>
                <div className='col-2'>
                    <button className="btn btn-link  App-link" name="home" onClick={(e) => handleChange(e)}>
                        LOLICA
                    </button>
                </div>
                <div className='col-8'>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/about_me" onClick={(e) => handleChange(e)}>ABOUT ME</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/animation" onClick={(e) => handleChange(e)} disabled>ANIMATION</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/illustration_photos" onClick={(e) => handleChange(e)}>ILLUSTRATION & PHOTOS</button>
                    <button className='btn btn-sm btn-outline-dark App-button' name="/personal_projects" onClick={(e) => handleChange(e)}>PERSONAL PROJECTS</button>
                </div>
                <div className='col-2'>
                    <a href="https://www.instagram.com/lazovic_oljica" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/instagram.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                    <a href="https://www.behance.net/oljicalazovic" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/behance.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                    <a href="https://vimeo.com/user124165686" target="_blank" rel="noopener noreferrer">
                        <img src={require(`./images/icons/vimeo.png`)} alt='' height={30} className="App-icons"></img>
                    </a>
                </div>
            </div>
            <div className="container">
                <div className="row Single-card text-start">
                    <div className="col-12">
                        <p className="Title">“Hungry”</p>
                        <p className="Product-type">( video )</p>
                        <a href="https://vimeo.com/837515426" target="_blank" rel="noopener noreferrer">
                            <img src={require(`./images/animacije/photo_1.png`)} alt='' className="img-fluid Projekti-slike"></img>
                        </a>
                        <p>
                            Video has been exhibitet on behalf of ULUS “My name is quite ordinary” and it tackeled the theme of systematic uncertainty
                        </p>
                    </div>
                </div>
                <div className="row Single-card text-start">
                    <div className="col-5">
                        <p className="Title">“New world order”</p>
                        <p className="Product-type">( video )</p>
                        <a href="https://vimeo.com/837595724" target="_blank" rel="noopener noreferrer">
                            <img src={require(`./images/animacije/photo_3.png`)} alt='' width={1000} className="img-fluid Projekti-slike"></img>
                        </a>
                    </div>
                    <div className="col-2"></div>
                    <div className="col-5">
                        <p className="Title">“A nice fall 101”</p>
                        <p className="Product-type">( video )</p>
                        <a href="https://vimeo.com/837574090" target="_blank" rel="noopener noreferrer">
                            <img src={require(`./images/animacije/photo_4.png`)} alt='' width={1000} className="img-fluid Projekti-slike"></img>
                        </a>
                    </div>
                </div>
            </div>
            <div>
                <div className="row Single-card Master text-start">
                    <div className="col-12">
                        <br />
                        <p className="Master-text">
                            I have a masters degree in creative photography and i will show you how i have conected photography with interactiv 3d programs.
                            How i strive to make other worlds with visuals i have manifested. Perception through 3d and 2d have really been amazing.
                            And i have decided to make a vizualization of some of my emotions, just in a playfull way.
                        </p>
                        <br />
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row Single-card text-start">
                    <div className="col-12">
                        <p className="Title">“Anxiety”</p>
                        <p className="Product-type">( video )</p>
                        <a href="https://vimeo.com/891782513" target="_blank" rel="noopener noreferrer">
                            <img src={require(`./images/animacije/photo_5.png`)} alt='' width={1200} className="img-fluid Projekti-slike"></img>
                        </a>
                    </div>
                </div>
                <div className="row Single-card text-start">
                    <div className="col-12">
                        <p className="Title">“Hope”</p>
                        <p className="Product-type">( video )</p>
                        <a href="https://vimeo.com/891782611" target="_blank" rel="noopener noreferrer">
                            <img src={require(`./images/animacije/photo_6.png`)} alt='' width={1200} className="img-fluid Projekti-slike"></img>
                        </a>
                    </div>
                </div>
                <div className="row Single-card text-start">
                    <div className="col-12">
                        <p className="Title">“Happines”</p>
                        <p className="Product-type">( video )</p>
                        <a href="https://vimeo.com/891782558" target="_blank" rel="noopener noreferrer">
                            <img src={require(`./images/animacije/photo_7.png`)} alt='' width={1200} className="img-fluid Projekti-slike"></img>
                        </a>
                    </div>
                </div>
                <div className="row Single-card text-start">
                    <div className="col-12">
                        <p className="Title">“Apathy”</p>
                        <p className="Product-type">( video )</p>
                        <a href="https://vimeo.com/891782702" target="_blank" rel="noopener noreferrer">
                            <img src={require(`./images/animacije/photo_8.png`)} alt='' width={1200} className="img-fluid Projekti-slike"></img>
                        </a>
                    </div>
                </div>
                <div className="row Single-card text-start">
                    <div className="col-12">
                        <p className="Title">“Fear”</p>
                        <p className="Product-type">( video )</p>
                        <a href="https://vimeo.com/891782820" target="_blank" rel="noopener noreferrer">
                            <img src={require(`./images/animacije/photo_9.png`)} alt='' width={1200} className="img-fluid Projekti-slike"></img>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Animation;