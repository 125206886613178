import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css';
import Main from './Pages/Main';
import AboutMe from './Pages/AboutMe';
import Animation from './Pages/Animation';
import IllustrationPhotos from './Pages/IllustrationPhotos';
import PersonalProjects from './Pages/PersonalProjects';
import { useEffect } from 'react';
function App() {

  useEffect(()=>{
    if(localStorage.getItem("slike")==null){
      localStorage.setItem("slike", JSON.stringify([
        {imeSlike: "photo_1"},
        {imeSlike: "photo_2"},
        {imeSlike: "photo_3"},
        {imeSlike: "photo_4"},
        {imeSlike: "photo_5"},
        {imeSlike: "photo_6"},
        {imeSlike: "photo_7"},
        {imeSlike: "photo_8"},
        {imeSlike: "photo_9"},
        {imeSlike: "photo_10"},
        {imeSlike: "photo_11"},
        {imeSlike: "photo_12"},
        {imeSlike: "photo_13"},
        {imeSlike: "photo_14"},
        {imeSlike: "photo_15"},
        {imeSlike: "photo_16"},
        {imeSlike: "photo_17"},
        {imeSlike: "photo_18"}
    ]))
    }
  })

  return (
    <div className="App">
      <div className='container-fluid'>
        <BrowserRouter>
          <Routes>
            <Route path='' element={<Main></Main>}></Route>
            <Route path='about_me' element={<AboutMe></AboutMe>}></Route>
            <Route path='animation' element={<Animation></Animation>}></Route>
            <Route path='illustration_photos' element={<IllustrationPhotos slike={JSON.parse(localStorage.getItem("slike") || '{}')}></IllustrationPhotos>}></Route>
            <Route path='personal_projects' element={<PersonalProjects></PersonalProjects>}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
